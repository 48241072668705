import $ from "jquery";

// ハンバーガーメニュー
const active = '-active';
$('#js-hamburger').on('click', (event) => {
  $(event.currentTarget).toggleClass('-active');
  if($(event.currentTarget).hasClass(active)) {
    $('body').css('overflow', 'hidden');
  } else {
    $('body').css('overflow', 'visible');
  }
  $('#js-headerNav').slideToggle();
  return false;
});
