import $ from "jquery";

let pos = $('#js-headerNav').offset().top;

$(window).on('scroll', function() {
  if($(this).scrollTop() > pos) {
    $('#js-headerNav').addClass('-fixed');
    } else {
    $('#js-headerNav').removeClass('-fixed');
  }
});

$(window).on('resize', function() {
  pos = $('#js-headerNav').offset().top;
});
